import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import { HeadingType, TextType } from "@utils/types";
import { useSticky } from "@hooks";
import {
  StickyWrapper,
  StickyInner,
  StickyListWrap,
  StickyList,
  StickyItem,
} from "./style";
import { Anchor } from "react-bootstrap";

const StickyMenu = ({ data,setShowRestComponents,showRestComponents }) => {
  const { sticky, headerRef, fixedRef } = useSticky();
  const [scrollTargetId, setScrollTargetId] = useState(null);
  const tabActive = (e, id) => {
    if (typeof setShowRestComponents === 'function') {
      setShowRestComponents(true);
    }
    setScrollTargetId(id);
    const elements = document.querySelectorAll(".listItem");
    elements.forEach((el) => el.classList.remove("active"));
    e.currentTarget.classList.add("active");
  };
  useEffect(() => {
    if (showRestComponents) {
      if (scrollTargetId) {
        document.getElementById(scrollTargetId)?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } else {
      setScrollTargetId(null);
    }
  }, [showRestComponents, scrollTargetId]);
  return (
    <StickyWrapper ref={fixedRef} isStickyMenu={sticky}>
      <StickyInner ref={headerRef}>
        <Container>
          <Row>
            <Col lg={12}>
              <StickyListWrap>
                <StickyList>
                  {data &&
                    data?.items?.map((el, index) => (
                      <StickyItem
                        className="listItem"
                        key={index}
                        onClick={(e) => tabActive(e, el.path)}
                      >
                        <Anchor>{el.title}</Anchor>
                      </StickyItem>
                    ))}
                </StickyList>
              </StickyListWrap>
            </Col>
          </Row>
        </Container>
      </StickyInner>
    </StickyWrapper>
  );
};

StickyMenu.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
  }),
};

export default StickyMenu;
